export const params = {
ui: "https://cataloguiqa.moglix.com/",
elastic: "https://api.moglix.com",
// Old Path for Image
//images: "https://img.moglimg.com/",
auth: "https://authqa.moglilabs.com",
prod_supp: "https://catalogapi-qa1.moglilabs.com",
ems: "https://emsqa.moglilabs.com",
old_prod_supplier: "https://platformdata-qa1.moglilabs.com",
old_report_module: "https://platformreports-qa1.moglilabs.com",
online_redirect_path: "https://www.qa.moglilabs.com/",
// New Path for Images
images: "https://imgqa.moglix.com/",
// SaleOps portal
saleOps_portal: "https://salesopsqa.moglilabs.com",
spark_module: "https://sparkapi.moglilabs.com",
// Offer Price config
price_engine: "https://price-engineqa.moglilabs.com",
user_management: "https://authqappe.moglilabs.com",
user_management_secret_key: "Q2F0YWxvZ2h0dHA6Ly9jYXRhbG9ndWlxYS5tb2dsaXguY29tLw==",
//audit log
audit_log: "https://catalogueauditlogapiqa.moglilabs.com",
audit_log_appID: "J6M1-XEBNcuKOvdqxG9v",
country_list: "http://platformdataqa2.moglix.com",
subCategories: 'https://platformdataqa2.moglix.com',
analyticsReport: "https://analysis.moglix.com/centralsyncreportapi",
downloadReport: "https://analysis.moglix.com",
uploadDocuments: "https://platformdataqa2.moglix.com/cassandraFeed",
approval_endPoint: "https://catalogservice-qa1.moglilabs.com",
analytics_endPoint: "https://analytics.moglilabs.com",
platform_data_feed: "https://platformdatafeedservice-qa1.moglilabs.com",
price_manager: 'https://pricemanagerapiqa.moglilabs.com',
api_gateway: "https://catalogueapi-dev.moglilabs.com",
gridMaxSize: 7,
google_login_id: "1063228191315-sj1120oqp2bg8o31uenrf37nl1hva3er.apps.googleusercontent.com",
cisPortal:'https://cis.moglilabs.com/category-discovery',
//Online Module
onlineMerchant:"https://online-merchandise.moglilabs.com",
//feature images
feature_images: "https://imgqa.moglix.com/"
  
}
