// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // CLIENT_ID:  '49bee008-fe4a-4b36-85e0-fd94a9c9dd4d',
   CLIENT_ID: '7ef76858-5a61-4c38-9937-e94e999bac4a',
  // CLIENT_ID: 'd59ffac7-ad33-4023-a7a1-07766de71f80',
  TENANT_ID: '421e9584-87ff-424f-95b8-1bf46b70db99'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.